// See: https://www.gatsbyjs.org/blog/2019-02-20-introducing-use-static-query/

function useContactData() {
  const { contactYaml } = useStaticQuery(
    graphql`
      query {
        contactYaml {
          heading

          management {
            heading
            company {
              text
              href
            }
            street
            city
            tel {
              text
              href
            }
            tel2 {
              text
              href
            }            
            email {
              text
              href
            }
          }

          publicRelations {
            heading
            company {
              text
              href
            }
            agent
            street
            city
            tel {
              text
              href
            }
            email {
              text
              href
            }
          }

          lesley {
            heading
            email {
              text
              href
            }
            twitter {
              text
              href
            }
            facebook {
              text
              href
            }
            instagram {
              text
              href
            }
            imdb {
              text
              href
            }
          }
        }
      }
    `
  )

  return contactYaml
}

///////////////////////////////////////////////////////////////////////////////////

import { useStaticQuery, graphql } from 'gatsby'

export default useContactData

/*

import useContactData from '../data/useContactData'

const { title, description, url } = useContactPageMetadata()
const { heading, management, publicRelations, lesley } = useContactData()

*/
