function ContactPage() {
  const { contactPage } = useSiteMetadata()
  const { heading, management, publicRelations, lesley } = useContactData()

  return (
    <Base>
      <Metadata page={contactPage} />

      <Header>
        <PageTitle>{heading}</PageTitle>
      </Header>

      <Main id="main-content" tabIndex="-1">
        <Management management={management} />
        <PublicRelations publicRelations={publicRelations} />
        <Lesley lesley={lesley} />
      </Main>
    </Base>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Header = styled.header`
  padding-top: var(--s8);
  text-align: center;
`

const PageTitle = styled.h1`
  padding-top: var(--s5);
  font-size: var(--f6);
  font-weight: normal;
`

const Main = styled.main`
  text-align: center;
  line-height: var(--lh3);
  font-size: 0.96rem;
`

///////////////////////////////////////////////////////////////////////////////////

function Management({ management }) {
  return (
    <ManagementSection>
      <Heading>{management.heading}</Heading>
      <Link href={management.company.href}>{management.company.text}</Link>
      <Address>
        {management.street}
        <br />
        {management.city}
      </Address>
      <Link href={management.tel.href}>{management.tel.text}</Link>
      <br />
      <br />
      <Link href={management.email.href}>{management.email.text}</Link>
      <br />
      <Link href={management.tel2.href}>{management.tel2.text}</Link>
    </ManagementSection>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const ManagementSection = styled.section`
  margin-top: var(--s6);
  padding-top: var(--s4);
`

const Heading = styled.h2`
  padding-bottom: var(--s2);
  font-size: var(--f3);
  text-transform: uppercase;
`

const Address = styled.address`
  font-style: normal;
`

///////////////////////////////////////////////////////////////////////////////////

function PublicRelations({ publicRelations }) {
  return (
    <PublicRelationsSection>
      <Heading>{publicRelations.heading}</Heading>
      <p>{publicRelations.agent}</p>
      <Link href={publicRelations.company.href}>
        {publicRelations.company.text}
      </Link>
      <Address>
        {publicRelations.street}
        <br />
        {publicRelations.city}
      </Address>
      <Link href={publicRelations.tel.href}>{publicRelations.tel.text}</Link>
      <br />
      <Link href={publicRelations.email.href}>{publicRelations.email.text}</Link>
    </PublicRelationsSection>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const PublicRelationsSection = styled.section`
  padding-top: var(--s7);
`

///////////////////////////////////////////////////////////////////////////////////

function Lesley({ lesley }) {
  return (
    <LesleySection>
      <Heading>{lesley.heading}</Heading>
      <Link href={lesley.email.href}>{lesley.email.text}</Link>
      <br />
      <Link href={lesley.twitter.href}>{lesley.twitter.text}</Link>
      <br />
      <Link href={lesley.facebook.href}>{lesley.facebook.text}</Link>
      <br />
      <Link href={lesley.instagram.href}>{lesley.instagram.text}</Link>
      <br />
      <Link href={lesley.imdb.href}>{lesley.imdb.text}</Link>
    </LesleySection>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const LesleySection = styled.section`
  padding-top: var(--s7);
  padding-bottom: var(--s10);
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'

import Base from '../ui/Base'
import Metadata from '../ui/Metadata'
import { Link } from '../ui/elements'
import useSiteMetadata from '../data/useSiteMetadata'
import useContactData from '../data/useContactData'

export default ContactPage
